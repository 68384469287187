<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>

            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Period From </span>
                                <v-menu ref="startDateModal" v-model="startDateModal" transition="scale-transition"
                                    offset-y min-width="auto" max-width="290px" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="ma-0 mt-2 border-12" v-model="startDate" dense clearable outlined
                                            persistent-hint append-icon="mdi-calendar"
                                            v-bind="attrs" v-on="on" hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startDate"
                                        type="month"
                                        no-title @input="startDateModal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Period To </span>
                                <v-menu ref="endDateModal"
                                v-model="endDateModal"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        outlined
                                        v-model="endDate"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 mt-2 border-12"
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="endDate"
                                        type="month"
                                        no-title @input="endDateModal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" lg="1">
                                <span class="blue-lcd mb-2 font-12">Type </span>
                                <v-autocomplete
                                    prepend-inner-icon="mdi-compost"
                                    clearable
                                    outlined
                                    dense
                                    v-model="type"
                                    :items="types"
                                    item-value="value"
                                    item-text="text"
                                     class="ma-0 mt-2 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="mt-5 border-12" style="padding: 19px;" color="info" elevation="2" small @click="submit()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col-12" sm="12" md="12">
                <v-card outlined height="545">
                    <v-card-text>
                        <div class="d-flex w-100 mb-3">
                            <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-poll</v-icon>Chart Analysis Real Dummy (TON) </h6>
                            <v-spacer></v-spacer>
                        </div>
                        <v-img
                            class="text-center mt-5 mx-auto"
                            lazy-src="@/assets/empty_data.png"
                            max-width="300"
                            src="@/assets/empty_data.png"
                            v-if="showData"
                        ></v-img>
                        <div id="container1" style="height: 450px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
                <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="1200px"
                    hide-overlay
                    transition="dialog-bottom-transition"
                >
                    <v-card class="rounded-l">
                        <v-card-title class="text-center mb-0 pb-0">
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex p-2">
                                        <div class="ml-1">
                                            <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-details</v-icon> {{ detail ? detail.period : '' }}</h6>
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="mx-2"
                                            fab
                                            x-small
                                            color="#e83e8c;"
                                            @click="[dialog = false]"
                                        >
                                            <v-icon>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-divider></v-divider>
                            </v-row>
                        </v-card-title>
                        <v-card-text class="ma-0">
                            <v-container fluid>
                                <v-row>
                                    <v-col class="col-12" sm="6" md="6">
                                        <v-card outlined>
                                            <v-card-text>
                                                <v-container fluid>
                                                    <v-row>
                                                        <v-col class="col-6" sm="6" md="4">
                                                            <span class="mb-2 font-12">Tot. Wgt Coil </span>
                                                            <h6 class="blue-lcd" style="font-weight: 700;">{{ $store.getters.convertToCurrencyUs(total_wgt_coil / 1000) }} TON</h6>
                                                        </v-col>
                                                        <v-col class="col-6" sm="6" md="4">
                                                            <span class="mb-2 font-12">Tot. Wgt Dummy </span>
                                                            <h6 class="blue-lcd" style="font-weight: 700;">{{ $store.getters.convertToCurrencyUs(total_wgt_dummy / 1000) }} TON</h6>
                                                        </v-col>
                                                        <v-col class="col-6" sm="6" md="4">
                                                            <span class="mb-2 font-12">Tot. Wgt Real Dummy </span>
                                                            <h6 class="blue-lcd" style="font-weight: 700;">{{ $store.getters.convertToCurrencyUs(total_real_dummy / 1000) }} TON</h6>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-data-table
                                            dense
                                            :headers="header"
                                            :items="detailData"
                                            class="elevation-1 mt-3"
                                            :items-per-page="30"
                                            :footer-props="{
                                                'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                            }"
                                            :loading="$store.state.overlay"
                                            height="400"
                                            fixed-header
                                            :divider="true"
                                            :light="true"
                                            :search="searchItem" 
                                            :item-class="tr_datatable"
                                        >    
                                            <template v-slot:top>
                                                <v-toolbar flat color="white" class="mt-2 mb-2">
                                                    <div class="d-flex w-100">
                                                        <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                        <v-spacer></v-spacer>
                                                        <!-- <v-btn
                                                        small
                                                        color="#005c37"
                                                        class="py-5 mr-3 text-white"
                                                        >
                                                            <v-icon>mdi-file-excel</v-icon>
                                                            <download-excel
                                                                class="text"
                                                                :fetch           = "exportExcelItem"
                                                                :fields="headersColumn"
                                                                :before-generate = "startDownload"
                                                                :before-finish   = "finishDownload">
                                                                Export Excel
                                                            </download-excel>
                                                        </v-btn> -->
                                                    </div>
                                                </v-toolbar>
                                            </template>
                                            <template v-slot:[`item.dt_trx`]="{ item }">
                                                {{ (new Date(new Date(item.dt_trx) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)  }}
                                            </template> 
                                            <template v-slot:[`item.no_pass`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.no_pass) }}
                                            </template> 
                                            <template v-slot:[`item.wgt`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.wgt) }}
                                            </template> 
                                            <template v-slot:[`item.wgt_dummy`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(parseFloat(item.wgt_dummy)) }}
                                            </template> 
                                            <template v-slot:[`item.real_dummy`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.real_dummy) }}
                                            </template> 
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                            
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data(){
        return{
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'Sunrise Mill',
                    disabled: false,
                    href: '/admin/sm',
                },
                {
                    text: 'Production',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
                {
                    text: 'Real Dummy',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
            thick:'',
            thicks:[],
            width:'',
            widths:[],
            types:[
                {text: 'DS', value: 'DS'},
                {text: 'DR', value: 'DR'},
            ],
            type:'',
            startDateModal:false,
            startDate:'',
            endDateModal:false,
            endDate:'',
            searchItem: '',
            header:[],
            headersColumn:{},
            data:[],
            showDataDetail:true,
            last_year:"",
            lastPeriode:"",
            lastPeriodeAveragePl:0,
            lastPeriodeTotalCoil:0,
            avgWelding: 0,
            lengthWelding: 0,
            realOffGauge: 0,
            searchItem: '',
            header:[],
            data:[],
            avgOffGaugeLy:0,
            avgPcrLy:0,
            showData: true,
            x: window.matchMedia("(max-width: 991px)"),
            dialog: false,
            detail: {},
            header: [
                { text: 'Dt Trans', value: 'dt_trx' , align:'start'},
                { text: 'Type', value: 'Type' , align:'left'},
                { text: 'TR ID', value: 'tr_id' , align:'left'},
                { text: 'HRP ID', value: 'hrp_id' , align:'left'},
                { text: 'Coil ID', value: 'coil_id' , align:'left'},
                { text: 'No Pass', value: 'no_pass' , align:'left'},
                { text: 'Type Dummy', value: 'dummy_type' , align:'left'},
                { text: 'No Dummy', value: 'no_dummy' , align:'left'},
                { text: 'Scrab ID', value: 'scrab_id' , align:'left'},
                { text: 'Wgt Coil (Kg)', value: 'wgt' , align:'right'},
                { text: 'Wgt Dummy (Kg)', value: 'wgt_dummy' , align:'right'},
                { text: 'Wgt Real Dummy (Kg)', value: 'real_dummy' , align:'right'},
            ],
            searchItem: '',
            detailData: []
        }
    },
    async mounted(){
    },
    computed: {
        total_wgt_coil(){
			var total_wgt_coil = 0

            for (let index = 0; index < this.detailData.length; index++) {
                total_wgt_coil = total_wgt_coil + parseFloat(this.detailData[index].wgt);
            }
            
            return parseFloat(total_wgt_coil)
        },
        total_wgt_dummy(){
			var total_wgt_dummy = 0

            for (let index = 0; index < this.detailData.length; index++) {
                total_wgt_dummy = total_wgt_dummy + parseFloat(this.detailData[index].wgt_dummy);
            }
            
            return parseFloat(total_wgt_dummy)
        },
        total_real_dummy(){
			var total_real_dummy = 0

            for (let index = 0; index < this.detailData.length; index++) {
                total_real_dummy = total_real_dummy + parseFloat(this.detailData[index].real_dummy);
            }
            
            return parseFloat(total_real_dummy)
        }
    },
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        async submit(){
            let startPeriode = this.startDate.replace("-", "")
            let endPeriode = this.endDate.replace("-", "")
            var reqBody = {
                'startPeriod' : startPeriode,
                'endPeriod' : endPeriode,
                'Type': this.type
            }
            this.$store.dispatch('setOverlay', true)
            const respData = await backendApi.fetchCore('/api/v3/sm/production/real-dummy', reqBody, false, false, false)
            if (respData.status === 200) {
                this.showData = false
                this.$store.dispatch('setOverlay', false)
                // console.log(respData.data);
                var dummy = []
                var coil = []
                var real = []
                var container = 'container1'
                for (let index = 0; index < respData.data.length; index++) {
                    const element = respData.data[index];
                    // console.log(element.period.substr(0, 4) + '-' + element.period.substr(4, 6));
                    
                    dummy.push(
                        {
                            x: new Date(element.period.substr(0, 4) + '-' + element.period.substr(4, 6)),
                            y: parseFloat(element.wgt_dummy / 1000),
                            period: element.period,
                            type: 'dummy'
                        }
                    )

                    coil.push(
                        {
                            x: new Date(element.period.substr(0, 4) + '-' + element.period.substr(4, 6)),
                            y: parseFloat(element.wgt_coil / 1000),
                            period: element.period,
                            type: 'coil'
                        }
                    )

                    real.push(
                        {
                            x: new Date(element.period.substr(0, 4) + '-' + element.period.substr(4, 6)),
                            y: parseFloat(element.wgt_real_dummy / 1000),
                            period: element.period,
                            type: 'real'
                        }
                    )
                }
                console.log(dummy, coil, real);
                
                await this.getChart(dummy, coil, real, "Wgt Dummy", "Wgt Coil", "Wgt Real Dummy", container, 'column');
                
            } else{
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
        },
        getChart(dt1, dt2, dt3, name1, name2, name3, container){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: 'Real Dummy',
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: 'Weight',
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisX: {
                    interval: 1,
                    intervalType: "month"
                },
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "TON",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    itemclick: this.toggleDataSeries,
                    fontSize: 11
                },
                data: [
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name2,
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                        markerBorderColor: "white",
		                markerBorderThickness: 2,
                        // color: "#1E88E5",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name1,
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                        // color: "#64B5F6",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name3,
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                        // color: "#BBDEFB",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    
                    
                ]
            });

            chart.options.data[0].dataPoints = dt2;
            chart.options.data[1].dataPoints = dt1;
            chart.options.data[2].dataPoints = dt3;

            // this.showDefaultText(chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");
            if (x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        async showDetail(e){
            this.detail =  e.dataPoint
            var reqBody = {
                'period' : e.dataPoint.period,
                'Type': this.type
            }
            this.$store.dispatch('setOverlay', true)
            const respData = await backendApi.fetchCore('/api/v3/sm/production/real-dummy/show', reqBody, false, false, false)
            if (respData.status === 200) {
                this.$store.dispatch('setOverlay', false)
                this.dialog = true
                this.detailData = respData.data
                console.log(respData.data);                
            } else{
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
        },
    }
}
</script>
<style scoped>

</style>